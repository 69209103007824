import { useCallback } from 'react';

import AuthErrorMessage from '~/components/auth/AuthErrorMessage';
import EmailPasswordSignInForm from '~/components/auth/EmailPasswordSignInForm';
import useSignInWithEmailPassword from '~/core/hooks/use-sign-in-with-email-password';

const EmailPasswordSignInContainer: React.FCC<{
  onSignIn: (email: string, userId?: string) => unknown;
}> = ({ onSignIn }) => {
  const signInMutation = useSignInWithEmailPassword();
  const isPending = signInMutation.isPending;

  const onSubmit = useCallback(
    async (params: { email: string; password: string }) => {
      try {
        const data = await signInMutation.mutateAsync(params);
        const userId = data?.user?.id;

        onSignIn(params.email, userId);
      } catch {
        // wrong credentials, do nothing
      }
    },
    [onSignIn, signInMutation],
  );

  return (
    <>
      <AuthErrorMessage error={signInMutation.error} />

      <EmailPasswordSignInForm onSubmit={onSubmit} loading={isPending} />
    </>
  );
};

export default EmailPasswordSignInContainer;
